import React from 'react';

const BackgroundImage = ({ children }) => (
  <div className="background-image">
    <div className="overlay-gradient"></div>
    {children}
  </div>
);

export default BackgroundImage;
