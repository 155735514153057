import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Home from "./presentation/home/Home";
import ActionConfirmation from "./presentation/actionConfirmation/ActionConfirmation";
import EmailConfirmation from "./presentation/emailConfirmation/EmailConfirmation";
import Invite from "./presentation/invite";

function App() {
    return (
        <BrowserRouter>
            <div className="app">

                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/email-confirmation/:hash" element={<EmailConfirmation />} />
                    <Route exact path="/action-confirmation/:hash" element={<ActionConfirmation />} />
                    <Route exact path="/invite" element={<Invite />} />
                </Routes>

            </div>
        </BrowserRouter>
    );
}

export default App;
