import {Col, Container, Image, Row} from "react-bootstrap";
import "../../resources/main.css";
import {useLocation, useNavigate, useParams} from 'react-router';
import Api from "../../api/base/Api";
import React, { useEffect, useState } from 'react';
import Logo from "../../resources/images/emerge-logo.svg";
import ErrorImage from '../../resources/images/expired-graphic.svg';
import SuccessImage from '../../resources/images/open-email-graphic.svg';
import Navbar from "../Navbar/Navbar";
import BackgroundImage from "../BackgroundImage";


export default function ActionConfirmation(props) {
    const api = new Api(); 
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const error = params.get("error")
    const {hash} = useParams();
    const [message, setMessage] = useState('');
    const [message2, setMessage2] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        const url = window.location.href;
        
    
        if (hash) {
          api.confirmAction(hash)
            .then((success) => {
              setMessage('We have successfully confirmed your email address.');
              setMessage2('Please return to the app to continue.');
              setIsSuccess(true);
              
            })
            .catch(error => {
              setMessage('Action failed');
              setMessage2(`Action ${error.message.toLowerCase()}`)
              setIsSuccess(false);
            });
        }
      }, []);
    

      return (
        <>
          <BackgroundImage>
            <Navbar/>
            <div className="container">
              <div className="center-content">
                <div>
                  <img src={isSuccess ? SuccessImage : ErrorImage} className="svg-image" alt="Logo" />
                </div>
                <h1 className="heading">
                {isSuccess ? 'Email' : 'Action'} <span className="colour">{isSuccess ? "confirmed" : "failed"}</span>
                </h1>
                <h2 className="subheading">{message2} </h2>
                <a href="https://www.emerge.nz" >
                  <button className="mobile-button">Home</button>
                </a>
              </div>
            </div>
          </BackgroundImage>
        </>
      )
    
}