class Api {
    LOCAL_SERVER = "http://127.0.0.1:8080"
        DEV_SERVER = "https://dev.api.getignite.app"
        PROD_SERVER = "https://api.getignite.app"
        SERVER_URL = this.PROD_SERVER
    constructor() {
        
    }
  
    async confirmEmail(hash) {
      const response = await fetch(`${this.SERVER_URL}/api/v1/email/confirmations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hash }), 
      });
  
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    }

    async confirmAction(hash) {
      const response = await fetch(`${this.SERVER_URL}/api/v1/customer-actions/confirmations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hash }), 
      });
  
      if (response.status === 200) {
        return true;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
    }
  }
  
  export default Api;
  