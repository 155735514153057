import React from 'react';
import "../../resources/main.css"
import Logo from "../../resources/images/emerge-logo.svg";

const Navbar = () => (
  <div className="navbar">
    <div className="logo" style={{ position: 'absolute', top: 15, left: 30 }}>
      <img src={Logo} alt="Logo" />
    </div>
    <a href="https://www.emerge.nz" className="button-link" style={{ position: 'fixed', top: 20, right: 30 }}>
      <button className="nav-button">Home</button>
    </a>
  </div>
);

export default Navbar;
