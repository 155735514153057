import React from 'react';
import { Container } from 'react-bootstrap';
import '../../resources/main.css';
import GetStarted from '../../resources/images/phone-image-get-started-a.png';
import Navbar from '../Navbar/Navbar';
import BackgroundImage from '../BackgroundImage';
import  Apple  from './appleBadge';
import  Google  from './googleBadge';

export default function Invite(props) {
  return (
    <>
      <BackgroundImage>
        <Navbar />
        <Container>

          <div className="container-side">
            <div className="left">
              <h1 className="heading">
                Get <span className="colour">Started</span>
              </h1>
              <h2>
                You’ve been invited to join Emerge. <br />
                To get started, download the app and sign up.
              </h2>
              <div className="download-buttons">
                <a
                  href="https://apps.apple.com/nz/app/id6468939871"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='apple-store-button'
                >
                 <Apple/>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=app.emerge"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='google-play-button'
                >
                  <Google/>
                </a>
              </div>
              <a href="https://www.emerge.nz">
                <button className="mobile-button" style={{ margin: '30px auto' }}>
                  Home
                </button>
              </a>
            </div>
            <div className="right">
              <img className="side-image" src={GetStarted} alt="Image" />
            </div>

          </div>



        </Container>
      </BackgroundImage >
    </>
  );
}
