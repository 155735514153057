import {Col, Container, Image, Row} from "react-bootstrap";
import "./Home.css"
import Logo from "../../resources/images/emerge-logo.svg";
import {useLocation, useNavigate, useParams} from 'react-router';
import React, { useEffect, useState } from 'react';


export default function Home(props) {
   
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const error = params.get("error")
   
       
    

    return (
      <div style={{
        backgroundColor: "#010B3B",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <img
          src={Logo}
          className="logo"
          alt="Logo"
          style={{ maxWidth: "200px" }}
        />
      </div>
    )
    
    
}