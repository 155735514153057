import { Col, Container, Image, Row } from "react-bootstrap";
import "../../resources/main.css"
import Logo from "../../resources/images/emerge-logo.svg";
import ErrorImage from '../../resources/images/expired-graphic.svg';
import SuccessImage from '../../resources/images/open-email-graphic.svg';
import { useLocation, useNavigate, useParams } from 'react-router';
import Api from "../../api/base/Api";
import React, { useEffect, useState } from 'react';
import Navbar from "../Navbar/Navbar"
import BackgroundImage from "../BackgroundImage";


export default function EmailConfirmation(props) {
  const api = new Api();
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const error = params.get("error")
  const { hash } = useParams();
  const [message, setMessage] = useState('');  
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const url = window.location.href;


    if (hash) {
      api.confirmEmail(hash)
        .then(success => {
          if (success) {
            setMessage('We have successfully verified your email.');
            setIsSuccess(true);
          } else {
            setMessage('Email confirmation failed.');
          }
        })
        .catch(() => {
          setMessage('Email confirmation failed.');
          setIsSuccess(false);
        });
    }
  }, []);


  return (
    <>
      <BackgroundImage>
        <Navbar />
        <div className="container">
          <div className="center-content">
            <div>
              <img src={isSuccess ? SuccessImage : ErrorImage} className="svg-image" alt="Logo" />
            </div>
            <h1 className="heading">
              Email <span className="colour">{isSuccess ? "verified" : "failed"}</span>
            </h1>
            <h2 className="subheading">{message} </h2>
            <a href="https://www.emerge.nz" >
              <button className="mobile-button">Home</button>
            </a>
          </div>
        </div>
      </BackgroundImage>
    </>
  )

}